import { FirebaseUploadedImage, ServiceResponse } from 'interfaces';
import { FirebaseDB } from '../firebase-config/config';
import { collection, WithFieldValue, DocumentData, updateDoc, doc, getDoc, getDocs, deleteDoc, QueryConstraint, query, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

// Crea un nuevo documento en la colección que se le proporciona por parámetro
// 1. collectionRoute: ruta de la colección donde queremos almcenar el documento
// 2. document: objeto que contiene la información que queremos almacenar en el documento
export const createDocumentWithGeneratedId = async (collectionRoute: string, document: WithFieldValue<DocumentData>) => {
    let resp: ServiceResponse = {
        status: 500,
        title: '',
        message: '',
        data: ''
    };

    try {
        const collectionRef = collection(FirebaseDB, collectionRoute);

        let id = doc(collectionRef).id;

        await setDoc(doc(FirebaseDB, collectionRoute, id), { ...document, $key: id });

        resp = {
            status: 201,
            title: 'OK',
            message: 'Documento creado con éxito',
            data: id
        }
    } catch (error: any) {
        resp = {
            status: 500,
            title: 'Error',
            message: error.message
        }
    } finally {
        return resp;
    }
}

// Actualiza el documento que se le manda mediante un id
// 1. collectionRoute: ruta de la colleción donde se encuentra el documento
// 2. id: id del documento
// 3. objeto que contiene la información que queremos sobreescribir en el documento
export const updateDocument = async (collectionRoute: string, id: string, data: WithFieldValue<DocumentData>) => {
    let resp: ServiceResponse = {
        status: 500,
        title: '',
        message: '',
    }

    try {
        const docRef = doc(FirebaseDB, collectionRoute, id);

        // Verificamos si el documento existe
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            await updateDoc(docRef, data);

            resp = {
                status: 200,
                title: 'OK',
                message: 'Documento actualizado con éxito',
                data: docSnapshot.data().$key
            }
        } else {
            resp = {
                status: 404,
                title: 'Not found',
                message: 'El documento no fue encontrado'
            }
        }
    } catch (error: any) {
        resp = {
            status: 500,
            title: 'Error',
            message: error.message
        }
    } finally {
        return resp;
    }
}

// Carga un archivo en la ruta que se le proporciona por parámetro
// 1. image: objeto de tipo File que contiene la imagen que queremos subir
// 2. path: URL del bucket donde queremos almacenar la imagen
// 3. fileName: nombre que le queremos asignar a la imagen en el bucket
export const uploadImageToFirebaseBucket = async (image: File, path: string, fileName: string): Promise<FirebaseUploadedImage> => {
    const storage = getStorage();
    const imageRef = ref(storage, `${path}/${fileName}`);

    try {
        const { metadata } = await uploadBytes(imageRef, image);
        const url = await getDownloadURL(imageRef);

        return {
            ok: true,
            name: metadata.name,
            contentType: metadata.contentType,
            downloadURL: url,
            fullpath: metadata.fullPath,
        };
    } catch (error: any) {
        return {
            ok: false,
            name: "",
            contentType: "",
            downloadURL: "",
            fullpath: "",
            errorMessage: error.message || "Error desconocido al cargar la imagen a Firebase."
        };
    }
}

// Obtiene todos los documentos de una coleción. Se pueden incluir condiciones de búsqueda como parámetro
// para filtrar los documentos encontrados
// 1. collectionRoute: ruta de la colección que queremos consultar
// 2. queryConstrains: arreglo opcional que recibe los filtros de búsqueda
export const getDocumentsByFilterArray = async (collectionRoute: string, filters?: QueryConstraint[]) => {
    let resp: ServiceResponse = {
        status: 500,
        title: '',
        message: '',
        data: ''
    };

    try {
        let collectionRef = collection(FirebaseDB, collectionRoute);

        let snapshot = query(collectionRef);

        if (filters) {
            snapshot = query(collectionRef, ...filters);
        }

        const documents = await getDocs(snapshot);

        if (documents.size > 0) {
            resp = {
                status: 200,
                title: 'OK',
                message: 'Documentos encontrados',
                data: documents.docs.map((item) => item.data())
            }
        } else {
            resp = {
                status: 404,
                title: 'Not found',
                message: 'No se encontró ningún documento'
            }
        }
    } catch (error: any) {
        resp = {
            status: 500,
            title: 'Error',
            message: error.message
        }
    } finally {
        return resp;
    }
}

// Obtiene un documento a partir de su id
// 1. collectionRoute: ruta de la colección donde se encuentra el documento
// 2. id: id del documento que queremos consultar
export const getDocumentById = async (collectionRoute: string, id: string) => {
    let resp: ServiceResponse = {
        status: 500,
        title: '',
        message: '',
        data: ''
    };

    try {
        const docRef = doc(FirebaseDB, collectionRoute, id);

        const document = await getDoc(docRef);

        if (document.exists()) {
            resp = {
                status: 200,
                title: 'OK',
                message: 'Documentos encontrados',
                data: document.data()
            }
        } else {
            resp = {
                status: 404,
                title: 'Not found',
                message: 'No se encontró el documento solicitado'
            }
        }
    } catch (error: any) {
        resp = {
            status: 500,
            title: 'Error',
            message: error.message
        }
    } finally {
        return resp;
    }
}

// Elimina un documento a partir de su id
// 1. collectionRoute: ruta de la colección donde se encuentra el documento
// 2. id: id del documento que queremos consultar
export const deleteDocumentById = async (collectionRoute: string, id: string) => {
    let resp: ServiceResponse = {
        status: 500,
        title: '',
        message: '',
        data: ''
    };

    try {
        await deleteDoc(doc(FirebaseDB, collectionRoute, id));

        resp = {
            status: 200,
            title: 'OK',
            message: 'Documentos eliminado',
        }
    } catch (error: any) {
        resp = {
            status: 500,
            title: 'Error',
            message: error.message
        }
    } finally {
        return resp;
    }
}