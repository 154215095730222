import { FirebaseAuth } from "firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react"
import { logout } from "store/auth";
import { IonReactRouter } from "@ionic/react-router";
import { LoadingPage } from "components";
import { validateAuth } from "services";
import { SignInPage } from "app/auth";
import { DashboardDynamicPage } from "app/dashboard-dynamic";
import { SellersControlPage } from "app/seller-control";
import { GondolaColdPage } from "app/gondola-cold";
import { InventoryPage } from "app/inventory";
import { OrdersPage } from "app/orders";
import { IvaCardDetailPage, IvaCardsPage } from "app/iva-cards";
import { Redirect, Route } from "react-router";
import { HomePage } from "app/home";
import { loadAccessPermits } from "store/settings";
import { IonRouterOutlet } from "@ionic/react";

const routes = [
    { path: "/auth/signin", component: SignInPage, permission: "signin" },
    { path: "/home", component: HomePage, permission: "home" },
    { path: "/dashboard", component: DashboardDynamicPage, permission: "dynamic_dashboard" },
    { path: "/sellersControl", component: SellersControlPage, permission: "sellers_control" },
    { path: "/gondolaCold", component: GondolaColdPage, permission: "gondola_cold" },
    { path: "/inventory", component: InventoryPage, permission: "inventory" },
    { path: "/orders", component: OrdersPage, permission: "orders" },
    { path: "/ivaCards", component: IvaCardsPage, permission: "iva_cards" },
    { path: "/ivaCards/:cardKey", component: IvaCardDetailPage, permission: "iva_cards" },
];

export const MainRouter = () => {
    const { authStatus } = useAppSelector(state => state.auth);
    const { accessPermits } = useAppSelector(state => state.settings);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(FirebaseAuth, (user) => {
            if (user) {
                validateAuth(dispatch);
            } else {
                const accessPermitsAux = ["signin"];

                dispatch(loadAccessPermits(accessPermitsAux));

                dispatch(logout());
            }
        });

        // Limpia el listener cuando el componente se desmonte
        return () => unsubscribe();
    }, []);

    const renderRoutes = () => {
        return (
            <IonRouterOutlet>
                {
                    routes.map(route => {
                        if (accessPermits.includes(route.permission)) {
                            return <Route key={route.path} path={route.path} component={route.component} exact />;
                        }
        
                        if (authStatus === "authenticated") {
                            return <Redirect key={`${route.path}_home`} to="/home" />
                        } else {
                            return <Redirect key={`${route.path}signin`} to="/auth/signin" />
                        }
                    })
                }
            </IonRouterOutlet>
        )
    };


    if (authStatus === "checking") {
        return <LoadingPage />
    }

    return (
        <IonReactRouter>
                {
                    renderRoutes()
                }

            {/* {
                authStatus === "authenticated" &&
                <Redirect exact from="/" to="/home" />
            }

            {
                authStatus === "authenticated" &&
                <Redirect to="/home" />
            }

            {
                authStatus === "not-authenticated" &&
                <Redirect exact from="/" to="/auth/signin" />
            }

            {
                authStatus === "not-authenticated" &&
                <Redirect to="/auth/signin" />
            } */}
        </IonReactRouter>
    )
}