import { IonContent, IonIcon, IonPage, IonSpinner, useIonRouter } from "@ionic/react"
import { Pagination } from "@mui/material";
import { useAppSelector } from "hooks"
import { useEffect, useRef, useState } from "react"
import { playCircle } from 'ionicons/icons';
import { SearchField, SidebarMenu, SolidButton } from "components";
import { formatDateToLocaleFullDateString, formatTimestampToLocaleDate } from "utils";
import { collection, onSnapshot, orderBy, query, Unsubscribe, where } from "firebase/firestore";
import { ellipse, caretDown } from 'ionicons/icons';
import * as XLSX from 'xlsx';
import { FirebaseDB } from "firebase-config";

let vatListener: Unsubscribe | null = null;

type CardStatus = "all" | "reviewed" | "pending";

export const IvaCardsPage = () => {
    const { uid, vendorCode } = useAppSelector(state => state.user);

    // arreglo donde se almacena el listado completo de tarjetas de IVA
    const [clientsList, setClientsList] = useState<any[]>([]);
    
    // arreglo donde se almacena el listado de las tarjetas de IVA que coinciden
    // con el valor que ha introducido el usuario en la barra de búsqueda
    const [filteredClients, setFilteredClients] = useState<any[]>([]);

    // arreglo donde se almacena el listado completo de tarjetas que se encuentran PENDIENTES de revisión
    const [pendingClients, setPendingClients] = useState<any[]>([]);

    // arreglo donde se almacena el listado de las tarjetas de IVA que coinciden
    // con el valor que ha introducido el usuario en la barra de búsqueda y cuyo estado
    // es PENDIENTE
    const [filteredPendingClients, setFilteredPendingClients] = useState<any[]>([]);

    // arreglo donde se almacena el listado de tarjetas que ya fueron REVISADAS
    const [reviewedClients, setReviewedClients] = useState<any[]>([]);

    // arreglo donde se almacena el listado de las tarjetas de IVA que coinciden
    // con el valor que ha introducido el usuario en la barra de búsqueda y cuyo estado
    // es REVISADO
    const [filteredReviewedClients, setFilteredReviewedClients] = useState<any[]>([]);

    // arreglo donde se almacena el listado de clientes que se muestra en la vista
    const [visibleClients, setVisibleClients] = useState<any[]>([]);

    // estado que define el tipo de tarjetas que se muestran
    const [visibleStatus, setVisibleStatus] = useState<CardStatus>("all");

    // estado donde se almacena el texto que introduzca el usuario en la barra de búsqueda
    const [searcherValue, setSearcherValue] = useState<string>("");

    // estados referentes a la carga de las tarjetas
    const [loadingReport, setLoadingReport] = useState<boolean>(true);
    const [filteringReport, setFilteringReport] = useState<boolean>(false);
    const [errorGettingClients, setErrorGettingClients] = useState<boolean>(false);
    const [firstTime, setFirstTime] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");

    // estados referentes a la paginación
    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(12);

    // estado para manejar el menú desplegable de descarga
    const [openDownloadMenu, setOpenDownloadMenu] = useState<boolean>(false);

    const scrollableElementRef = useRef<any>(null);
    const ionRouter = useIonRouter();

    // referencia que se hace al estado que define el tipo de tarjetas que se muestran
    // esta referencia se usa en el método onSnapshot que está pendiente de recuperar los
    // cambios en las tarjetas de IVA que se recuperan desde base
    const visiblestatusRef = useRef(visibleStatus);

    // referencia que se hace al estado que almacena el texto que escribe el usuario en la
    // barra de búsqueda. Esta referencia se usa en el método onSnapshot que está pendiente de
    // recuperar los cambios en las tarjetas de IVA que se recuperan desde base
    const filterValueRef = useRef(searcherValue);

    // enlazamos con su referencia el estado que define el tipo de tarjetas que se muestran
    useEffect(() => {
        visiblestatusRef.current = visibleStatus;
    }, [visibleStatus]);

    // función que recupera las tarjetas de IVA desde base
    const getIvaCards = async () => {
        try {
            setLoadingReport(true);

            if (uid) {
                if (vendorCode) {
                    const vatRef = collection(FirebaseDB, "vat");

                    const vatQuery = query(
                        vatRef,
                        where("test", "==", false),
                        orderBy("createdAt", "desc")
                    );

                    vatListener = onSnapshot(vatQuery,
                        (snapshot) => {
                            const ivaCardsList: any[] = snapshot.docs.map((item: any) => item.data());

                            // guardamos el listado completo de tarjetas de IVA
                            setClientsList(ivaCardsList);

                            let filteredCardsArray = ivaCardsList;

                            // verificamos si el usuario ha escrito algo en el buscador y de ser así
                            // filtramos las tarjetas coincidentes
                            if (filterValueRef.current && filterValueRef.current !== "") {
                                filteredCardsArray = ivaCardsList.filter((client: any) => (
                                    client.cardCode?.toLowerCase().includes(filterValueRef.current) ||
                                    client.cardFName?.toLowerCase().includes(filterValueRef.current) ||
                                    client.cardName?.toLowerCase().includes(filterValueRef.current) ||
                                    client.numberDocument?.toLowerCase().includes(filterValueRef.current) ||
                                    client.nrc?.toLowerCase().includes(filterValueRef.current)
                                ));
                            }

                            // guardamos el listado de tarjetas filtradas
                            setFilteredClients(filteredCardsArray);

                            // guardamos el listado COMPLETO de tarjetas PENDIENTES
                            const pendingCardsArray = ivaCardsList.filter((item: any) => !item.status);
                            setPendingClients(pendingCardsArray);

                            // guardamos el listado FILTRADO de tarjetas PENDIENTES
                            const filteredPendingCardsArray = filteredCardsArray.filter((item: any) => !item.status);
                            setFilteredPendingClients(filteredPendingCardsArray);

                            // guardamos el listado COMPLETO de tarjetas REVISADAS
                            const reviewedCardsArray = ivaCardsList.filter((item: any) => item.status);
                            setReviewedClients(reviewedCardsArray);

                            // guardamos el listado FILTRADO de tarjetas REVISADAS
                            const filteredReviewedCardsArray = filteredCardsArray.filter((item: any) => item.status);
                            setFilteredReviewedClients(filteredReviewedCardsArray);

                            // determinamos el tipo de tarjetas que se van a mostrar en pantalla en función
                            // del filtro que ha escogido el usuario
                            let ivaCards: any[] = [];

                            switch (visiblestatusRef.current) {
                                case "all":
                                    ivaCards = ivaCardsList;
                                    break;
                                case "pending":
                                    ivaCards = filteredPendingCardsArray;
                                    break;
                                case "reviewed":
                                    ivaCards = filteredReviewedCardsArray;
                                    break;
                            }

                            // guardamos el listado de tarjetas visibles
                            const visibleClientsList = ivaCards.slice(recordsPerView * (currentPage - 1), recordsPerView * currentPage);
                            setVisibleClients(visibleClientsList);

                            setPaginationCount(Math.ceil(ivaCards.length / recordsPerView));
                            setErrorGettingClients(false);
                            setLoadingReport(false);
                        },
                        (error: any) => {
                            setErrorMessage("Ocurrió un error al intentar recuperar el listado de clientes. Intenta recargar nuevamente la página y si el error persiste comunícate con el equipo de soporte técnico");
                            setErrorGettingClients(true);
                            setLoadingReport(false);
                        },
                    )
                } else {

                }
            }
        } catch (error: any) {
            setErrorMessage(error.message);
            setErrorGettingClients(true);
        } finally {

        }
    }

    useEffect(() => {
        getIvaCards();

        return () => {
            if (vatListener) {
                vatListener();
                vatListener = null;
            }
        }
    }, []);

    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        // para mostrar las nuevas tarjetas que estarán visibles tomamos en cuenta 
        // el filtro de ESTADO DE TARJETA que ha escogido el usuario
        let filteredCards: any[] = [];

        switch (visibleStatus) {
            case "all":
                filteredCards = filteredClients;
                break;
            case "pending":
                filteredCards = filteredPendingClients;
                break;
            case "reviewed":
                filteredCards = filteredReviewedClients;
                break;
        }

        const visibleReportAux = filteredCards.slice(recordsPerView * (value - 1), recordsPerView * value);
        setVisibleClients(visibleReportAux);
        setCurrentPage(value);

        if (scrollableElementRef.current) {
            scrollableElementRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }


        setFilteringReport(false);
    }

    const changeSearcherValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearcherValue(event.target.value);
    }

    useEffect(() => {
        filterValueRef.current = searcherValue;

        if (!firstTime) {
            setFilteringReport(true);

            const timerId = setTimeout(async () => {
                if (searcherValue && searcherValue.length > 0) {
                    let inputValueAux = searcherValue.toLowerCase();

                    const filteredClientsArray = clientsList.filter((client: any) => (
                        client.cardCode?.toLowerCase().includes(inputValueAux) ||
                        client.cardFName?.toLowerCase().includes(inputValueAux) ||
                        client.cardName?.toLowerCase().includes(inputValueAux) ||
                        client.numberDocument?.toLowerCase().includes(inputValueAux) ||
                        client.nrc?.toLowerCase().includes(inputValueAux)
                    ));

                    setFilteredClients(filteredClientsArray);

                    // guardamos el listado de tarjetas pendientes
                    const pendingCardsArray = filteredClientsArray.filter((item: any) => (!item.hasOwnProperty("status") || !item.status));
                    setFilteredPendingClients(pendingCardsArray);

                    // guardamos el listado de tarjetas revisadas
                    const reviewedCardsArray = filteredClientsArray.filter((item: any) => (item.hasOwnProperty("status") && item.status));
                    setFilteredReviewedClients(reviewedCardsArray);

                    // para mostrar las nuevas tarjetas que estarán visibles tomamos en cuenta 
                    // el filtro de ESTADO DE TARJETA que ha escogido el usuario
                    let filteredCards: any[] = [];

                    switch (visibleStatus) {
                        case "all":
                            filteredCards = filteredClientsArray;
                            break;
                        case "pending":
                            filteredCards = pendingCardsArray;
                            break;
                        case "reviewed":
                            filteredCards = reviewedCardsArray;
                            break;
                    }

                    const visibleReportAux = filteredCards.slice(0, recordsPerView);
                    setVisibleClients(visibleReportAux);
                    setPaginationCount(Math.ceil(filteredCards.length / recordsPerView));
                } else {
                    setFilteredClients(clientsList);
                    setFilteredPendingClients(pendingClients);
                    setFilteredReviewedClients(reviewedClients);

                    let filteredCards: any[] = [];

                    switch (visibleStatus) {
                        case "all":
                            filteredCards = clientsList;
                            break;
                        case "pending":
                            filteredCards = pendingClients;
                            break;
                        case "reviewed":
                            filteredCards = reviewedClients;
                            break;
                    }

                    const visibleReportAux = filteredCards.slice(0, recordsPerView);
                    setVisibleClients(visibleReportAux);
                    setPaginationCount(Math.ceil(filteredCards.length / recordsPerView));
                }

                setCurrentPage(1);
                setFilteringReport(false);
            }, 1000);

            // Limpiar el temporizador anterior en cada cambio de inputValue
            return () => {
                clearTimeout(timerId);
            }
        } else {
            setFirstTime(false);
        }
    }, [searcherValue]);

    // Función para descargar el archivo
    const downloadClientsCodes = () => {
        const cardCodes = clientsList.map((item) => item.cardCode);

        // Convierte el arreglo en un formato que la biblioteca xlsx pueda entender
        const worksheetData: string[][] = cardCodes.map(item => [item]);
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Crea un nuevo libro de trabajo
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'CÓDIGOS DE CLIENTES');

        // Convierte el workbook a un archivo binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crea un Blob a partir del buffer binario
        const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `CÓDIGOS_CLIENTES_${formatDateToLocaleFullDateString(new Date())}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadClientsInfo = (clientsInfo: any[]) => {
        // Definir los encabezados de las columnas
        const headers = ['CÓDIGO DE CLIENTE', 'RAZÓN SOCIAL', 'NOMBRE DEL CONTRIBUYENTE', 'NIT', 'NRC', 'CORREO',
            'TELÉFONO', 'GIRO O ACTIVIDAD ECONÓMICA', 'DIRECCIÓN', 'DEPARTAMENTO', 'MUNICIPIO'];

        // Convertir el arreglo en un formato que la biblioteca xlsx pueda entender
        const worksheetData: string[][] = [
            headers, // Primera fila con los encabezados
            ...clientsInfo.map(item => [
                item.cardCode,
                item.cardFName,
                item.cardName,
                item.numberDocument,
                item.nrc,
                item.email,
                item.phoneNumber,
                item.economicActivity,
                item.address,
                item.departament,
                item.municipality
            ])
        ];

        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Crear un nuevo libro de trabajo
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TARJETAS DE IVA');

        // Convertir el workbook a un archivo binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crear un Blob a partir del buffer binario
        const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Tarjetas_IVA_${formatDateToLocaleFullDateString(new Date())}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadCompleteInformation = (clientsInfo: any[]) => {
        // Definir los encabezados de las columnas
        const headers = ['address', 'cardCode', 'cardFName', 'cardName', 'codeActivity', 'codeCategory',  'codeDepartament',
            'codeDistrict', 'codeMunicipality', 'createdAt', 'createdBy', 'departament',  'district', 'economicActivity', 'email',
            'municipality', 'nameCategory',  'nrc', 'numberDocument', 'phoneNumber', 'providedBy'];

        // Convertir el arreglo en un formato que la biblioteca xlsx pueda entender
        const worksheetData: string[][] = [
            headers, // Primera fila con los encabezados
            ...clientsInfo.map(item => [
                item.address,
                item.cardCode,
                item.cardFName,
                item.cardName,
                item.codeActivity,
                item.codeCategory,
                item.codeDepartament,
                item.codeDistrict,
                item.codeMunicipality,
                formatTimestampToLocaleDate(item.createdAt),
                item.createdBy,
                item.departament,
                item.district,
                item.economicActivity,
                item.email,
                item.municipality,
                item.nameCategory,
                item.nrc,
                item.numberDocument,
                item.phoneNumber,
                item.providedBy
            ])
        ];

        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Crear un nuevo libro de trabajo
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TARJETAS DE IVA');

        // Convertir el workbook a un archivo binario
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Crear un Blob a partir del buffer binario
        const blob: Blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Tarjetas_IVA_${formatDateToLocaleFullDateString(new Date())}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        setFilteringReport(true);

        // para mostrar las nuevas tarjetas que estarán visibles tomamos en cuenta 
        // el filtro de ESTADO DE TARJETA que ha escogido el usuario
        let filteredCards: any[] = [];

        switch (visibleStatus) {
            case "all":
                filteredCards = [...filteredClients];
                break;
            case "pending":
                filteredCards = [...filteredPendingClients];
                break;
            case "reviewed":
                filteredCards = [...filteredReviewedClients];
                break;
        }

        const visibleReportAux = filteredCards.slice(0, recordsPerView);
        setVisibleClients(visibleReportAux);
        setPaginationCount(Math.ceil(filteredCards.length / recordsPerView));

        setCurrentPage(1);

        setFilteringReport(false);
    }, [visibleStatus])

    const toggleOpenDownloadMenu = () => {
        setOpenDownloadMenu(!openDownloadMenu);
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-screen grid grid-cols-12">
                    <div className="col-span-2 border-r-2 border-solid border-light">
                        <SidebarMenu pageName="iva_cards" />
                    </div>

                    {
                        loadingReport &&
                        <div className="w-full h-screen flex flex-col justify-center items-center col-span-10">
                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                        </div>
                    }

                    {
                        !loadingReport && errorGettingClients &&
                        <div className="w-full h-screen flex flex-col justify-center items-center p-4 col-span-10">
                            <div className="w-full flex flex-col items-center gap-4 p-4">
                                <p className="font-semibold text-primary text-center">{errorMessage}</p>

                                <div className="w-1/3">
                                    <SolidButton
                                        text="Recargar"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={getIvaCards}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        !loadingReport && !errorGettingClients &&
                        <div className="w-full h-screen flex flex-col items-center col-span-10">
                            <div className="w-full p-4 border-b-2 border-solid border-tertiary flex justify-between items-center">
                                <p className="font-bold">Tarjetas de I.V.A.</p>
                            </div>

                            <div className="w-full p-4 border-b-2 border-solid border-tertiary flex gap-12">
                                <div className="w-[70%] flex items-center gap-6">
                                    <div className="w-1/2">
                                        <SearchField onChangeHandler={changeSearcherValueHandler} />
                                    </div>

                                    <div className="flex items-center gap-3">
                                        <button onClick={() => setVisibleStatus("all")} className={`${visibleStatus === "all" && "bg-tertiary font-semibold"} border border-tertiary px-6 py-1 rounded-full`}>Todas</button>
                                        <button onClick={() => setVisibleStatus("pending")} className={`${visibleStatus === "pending" && "bg-tertiary font-semibold"} border border-tertiary  px-4 py-1 rounded-full`}>Pendientes</button>
                                        <button onClick={() => setVisibleStatus("reviewed")} className={`${visibleStatus === "reviewed" && "bg-tertiary font-semibold"} border border-tertiary px-4 py-1 rounded-full`}>Revisadas</button>
                                    </div>
                                </div>

                                <div className="relative inline-block text-left">
                                    <button 
                                        onClick={toggleOpenDownloadMenu} 
                                        className="bg-primary text-white font-bold py-2 px-5 flex items-center rounded-md gap-3"
                                    >
                                        Descargar
                                        <IonIcon icon={caretDown} className="text-[22px]" />
                                    </button>

                                    {
                                        openDownloadMenu && (
                                            <div className="absolute left-0 mt-2 w-48 bg-white z-20 logo-card text-mid font-semibold">
                                                <button
                                                    className="w-full block px-4 py-2 border-b border-tertiary hover:bg-light"
                                                    onClick={() => downloadCompleteInformation(visibleClients)}
                                                >
                                                    PÁGINA ACTUAL
                                                </button>
                                                <button
                                                    className="w-full block px-4 py-2 border-b border-tertiary hover:bg-light"
                                                    onClick={() => downloadCompleteInformation(filteredClients)}
                                                >
                                                    TODAS LAS PÁGINAS
                                                </button>
                                                <button
                                                    className="w-full block px-4 py-2 hover:bg-light"
                                                    onClick={downloadClientsCodes}
                                                >
                                                    CÓDIGOS DE CLIENTES
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {
                                filteringReport &&
                                <div className="w-full h-screen flex justify-center pt-6">
                                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                </div>
                            }

                            {
                                !filteringReport && !(visibleClients.length > 0) &&
                                <div className="w-full h-screen flex justify-center pt-6">
                                    <p className="font-bold text-primary">NO SE ENCONTRARON RESULTADOS</p>
                                </div>
                            }

                            {
                                !filteringReport && visibleClients.length > 0 &&
                                <div ref={scrollableElementRef} id="main-section" className="container h-screen overflow-y-scroll flex flex-col flex-grow">
                                    <div className="w-[90%] gap-4 px-8 py-4 grid grid-cols-3">
                                        {
                                            visibleClients.map((client: any) => (
                                                <div
                                                    key={client.$key}
                                                    className="flex flex-col flex-grow p-4 rounded-md account-information-card text-small font-medium"
                                                >
                                                    <div className="w-full flex justify-between">
                                                        <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.381 11.5605C17.0198 10.0797 16.2875 8.38215 14.7453 7.7688C13.2031 7.15546 11.4351 7.85863 10.7963 9.33938C10.1575 10.8201 10.8898 12.5177 12.432 13.1311C13.9742 13.7444 15.7422 13.0412 16.381 11.5605Z" fill="#434343" />
                                                            <path d="M25.5266 18.5338V10.149C26.0834 9.80969 26.4529 9.21335 26.4529 8.53475C26.4529 7.47573 25.5587 6.6172 24.4558 6.6172C24.1077 6.6172 23.7838 6.70202 23.5 6.85111L15.5758 2.65099C15.5116 1.64852 14.6469 0.854248 13.5867 0.854248C12.5266 0.854248 11.6833 1.62795 11.6003 2.61244L3.29328 6.7997C3.03627 6.68403 2.74982 6.6172 2.44731 6.6172C1.34435 6.6172 0.450195 7.47573 0.450195 8.53475C0.450195 9.08997 0.696489 9.58606 1.08735 9.93565V18.7497C0.696489 19.0993 0.450195 19.598 0.450195 20.1506C0.450195 21.2096 1.34435 22.0682 2.44731 22.0682C2.74982 22.0682 3.0336 22.0013 3.2906 21.8857L11.6003 26.0961C11.6833 27.0805 12.54 27.8568 13.5867 27.8568C14.6335 27.8568 15.5142 27.06 15.5758 26.0575L23.5 21.8368C23.7838 21.9859 24.1077 22.0682 24.4531 22.0682C25.556 22.0682 26.4502 21.2096 26.4502 20.1506C26.4502 19.472 26.0808 18.8757 25.5239 18.5364L25.5266 18.5338ZM22.4667 20.0324L14.5425 24.253C14.2587 24.104 13.9348 24.0191 13.5894 24.0191C13.2441 24.0191 12.8934 24.1117 12.6016 24.271L4.44443 20.1403C4.44443 19.3512 3.94381 18.6752 3.2317 18.3847V10.2929C3.94113 10.0025 4.43907 9.32645 4.44443 8.53989L12.5989 4.42974C12.8907 4.59168 13.2307 4.68422 13.5921 4.68422C13.9535 4.68422 14.264 4.59939 14.5478 4.45031L22.4693 8.65042C22.5095 9.28018 22.8656 9.82512 23.3876 10.1464V18.5312C22.8629 18.8525 22.5068 19.4 22.4693 20.0298L22.4667 20.0324Z" fill="#434343" />
                                                            <path d="M14.3953 15.3053H12.6177C10.9579 15.3053 9.61133 16.5982 9.61133 18.1919V20.148H17.4044V18.1919C17.4044 16.5982 16.0578 15.3053 14.398 15.3053H14.3953Z" fill="#434343" />
                                                        </svg>

                                                        {
                                                            client.hasOwnProperty("status") && client.status === true &&
                                                            <div className="flex items-center gap-1">
                                                                <IonIcon icon={ellipse} className="text-success text-[18px] mb-[2px]" />
                                                                <p className="text-success text-large font-bold">Revisado</p>
                                                            </div>
                                                        }

                                                        {
                                                            (!client.hasOwnProperty("status") || client.status === false) &&
                                                            <div className="flex items-center gap-1">
                                                                <IonIcon icon={ellipse} className="text-progress-bar-orange text-[18px] mb-[2px]" />
                                                                <p className="text-progress-bar-orange text-large font-bold">Pendiente</p>
                                                            </div>
                                                        }


                                                        {/* <div className="flex items-center gap-1">
                                                            <p className="text-success text-large font-bold">REVISADO</p>
                                                            <IonIcon icon={checkmarkCircle} className="text-success text-[22px] mb-[2px]" />
                                                        </div> */}
                                                    </div>

                                                    <p className="mt-3">{client.cardCode}</p>

                                                    <p className="mt-1 text-mid font-bold">{client.cardName}</p>
                                                    <p className="mb-2">{client.cardFName}</p>

                                                    <div className="w-full mt-auto h-[1px] border border-solid border-light"></div>

                                                    <div className="w-full mt-3 flex justify-between items-center">
                                                        <p>Ver datos de I.V.A.</p>

                                                        <IonIcon
                                                            onClick={() => ionRouter.push(`/ivaCards/${client.$key}`, "forward", "push", { unmount: false })}
                                                            icon={playCircle}
                                                            className="text-primary text-[25px] cursor-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                    <div className="w-full flex justify-center mt-auto py-4">
                                        <Pagination
                                            size="large"
                                            color="primary"
                                            count={paginationCount}
                                            defaultPage={1}
                                            page={currentPage}
                                            siblingCount={1}
                                            boundaryCount={2}
                                            onChange={changePaginationHandler}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}