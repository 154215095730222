import { alertCircle, checkmarkCircle } from 'ionicons/icons';
import { IonIcon, IonText } from "@ionic/react";
import { AutocapitalizeTextFieldValues, InputModeTextField } from "types";
import { useEffect, useState } from 'react';

export const TextArea = ({ name,
    label,
    value,
    inputMode,
    changeHandler,
    isFieldValid,
    errorMessage,
    required = false,
    placeholder,
    autoCapitalize = "sentences",
    maxLength,
    reference
}:
    {
        name: string,
        label: string,
        value: string,
        inputMode: InputModeTextField,
        changeHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
        isFieldValid?: boolean,
        errorMessage?: string,
        required?: boolean,
        placeholder?: string,
        autoCapitalize?: AutocapitalizeTextFieldValues,
        maxLength?: number,
        reference?: React.RefObject<HTMLTextAreaElement>
    }
) => {
    const [inputChanged, setInputChanged] = useState<boolean>(false);

    useEffect(() => {
        if (value.length > 0) {
            setInputChanged(true);
        }
    }, []);

    return (
        <div className="w-full flex flex-col gap-2">
            <div className="w-full flex">
                <label htmlFor={name} className="font-semibold text-[0.85rem] text-medium">
                    {label}
                    {
                        required &&
                        <IonText color="danger">
                            <span className="font-bold"> *</span>
                        </IonText>
                    }
                </label>
            </div>

            <div className="w-full flex items-center relative">
                <textarea
                    name={name}
                    id={name}
                    value={value}
                    inputMode={inputMode}
                    autoCapitalize={autoCapitalize}
                    placeholder={placeholder}
                    onChange={changeHandler}
                    onInput={() => setInputChanged(true)}
                    autoComplete="off"
                    maxLength={maxLength}
                    ref={reference}
                    className={`w-full text-mid dark:bg-color-toolbar dark:text-light pl-2 pr-4 py-[6px] rounded-md border border-solid focus:border-primary dark:border-light focus:outline-none focus:border-2 ${(!inputChanged || isFieldValid === undefined) && 'border-tertiary focus:border-primary'} ${isFieldValid !== undefined && inputChanged && !!isFieldValid && 'border-secondary focus:border-secondary'} ${isFieldValid !== undefined && inputChanged && !isFieldValid && 'border-success focus:border-success'}`}
                />
                {
                    isFieldValid !== undefined && !!isFieldValid && inputChanged && <IonIcon icon={alertCircle} className='absolute right-[8px] text-[0.95rem] text-danger' />
                }

                {
                    isFieldValid !== undefined && !isFieldValid && inputChanged && <IonIcon icon={checkmarkCircle} className='absolute right-[8px] text-[0.95rem] text-success' />
                }
            </div>
            {
                isFieldValid !== undefined && !!isFieldValid && inputChanged && <p className="text-danger font-semibold text-mid">{errorMessage}</p>
            }
        </div>
    )
}