import { alertCircle, checkmarkCircle } from 'ionicons/icons';
import { IonIcon } from "@ionic/react";
import { AutocapitalizeTextFieldValues, InputModeTextField } from "types";
import { useEffect, useState } from 'react';

export const TextField = (
    { name,
        label,
        value,
        inputMode,
        changeHandler,
        isFieldValid,
        errorMessage,
        required = false,
        placeholder,
        autoCapitalize = "sentences",
        maxLength,
        reference,
        disabled = false,
        autoValidate = false,
    }:
        {
            name: string,
            label: string,
            value: string,
            inputMode: InputModeTextField,
            changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
            isFieldValid?: boolean,
            errorMessage?: string,
            required?: boolean,
            placeholder?: string,
            autoCapitalize?: AutocapitalizeTextFieldValues,
            maxLength?: number,
            reference?: React.RefObject<HTMLInputElement>,
            disabled?: boolean,
            autoValidate?: boolean,
        }
) => {
    const [inputChanged, setInputChanged] = useState<boolean>(false);

    useEffect(() => {
        if (value.length > 0 || autoValidate) {
            setInputChanged(true);
        }
    }, []);

    return (
        <div className="w-full flex flex-col gap-1">
            <div className="w-full flex">
                <label htmlFor={name} className="font-semibold text-mid text-medium">
                    {label}
                    {
                        required &&
                        <span className="font-bold text-danger"> *</span>
                    }
                </label>
            </div>

            <div className="w-full flex items-center relative">
                <input
                    type="text"
                    name={name}
                    id={name}
                    value={value}
                    inputMode={inputMode}
                    autoCapitalize={autoCapitalize}
                    placeholder={placeholder}
                    onChange={changeHandler}
                    onInput={() => setInputChanged(true)}
                    onBlur={() => setInputChanged(true)}
                    autoComplete="off"
                    maxLength={maxLength}
                    ref={reference}
                    disabled={disabled}
                    className={`w-full text-mid dark:bg-color-toolbar dark:text-light pl-2 pr-6 py-[6px] rounded-md border border-solid focus:border-primary dark:border-light focus:outline-none focus:border-2 ${disabled && 'bg-tertiary'} ${(!inputChanged || isFieldValid === undefined) && 'border-tertiary focus:border-primary'} ${isFieldValid !== undefined && inputChanged && !!isFieldValid && 'border-secondary focus:border-secondary'} ${isFieldValid !== undefined && inputChanged && !isFieldValid && 'border-success focus:border-success'}`}
                />
                {
                    isFieldValid !== undefined && !!isFieldValid && inputChanged && <IonIcon icon={alertCircle} className='absolute right-[8px] text-[0.95rem] text-danger' />
                }

                {
                    isFieldValid !== undefined && !isFieldValid && inputChanged && <IonIcon icon={checkmarkCircle} className='absolute right-[8px] text-[0.95rem] text-success' />
                }
            </div>
            {
                isFieldValid !== undefined && !!isFieldValid && inputChanged && <p className="text-danger font-semibold text-mid">{errorMessage}</p>
            }
        </div>
    )
}